<template>
  <!-- 
    Lists the data from the browsers localStorage
  -->
  <Portlet
    :title="$t('localStorageInformationComponent.localStorageInformation')"
    icon="database"
    class="LocalStorageInformation mb-2"
  >
    <h4 class="mt-4">
      {{ $t('localStorageInformationComponent.localStorageInformation') }}
    </h4>
    <hr class="mt-1">

    <template v-if="filtersAvailable">
      <big>{{ filters.length }} {{ $t('entries') }}</big>

      <button
        class="btn btn-secondary float-right mb-3"
        @click="openConfirmClearFilters()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="trash"
        />
        <span>{{ $t('clear') }}</span>
      </button>
      <div class="clearfix" />

      <button
        v-if="showFilters == false"
        class="btn btn-secondary btn-block btn-sm"
        type="button"
        data-toggle="collapse"
        data-target="#collapseFilters"
        aria-expanded="false"
        aria-controls="collapseExample"
        @click="showFilters = !showFilters"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-down"
        />
        <span>
          {{ $t('show') }}
        </span>
      </button>
      <button
        v-else
        class="btn btn-secondary btn-block btn-sm rounded-0"
        type="button"
        data-toggle="collapse"
        data-target="#collapseFilters"
        aria-expanded="false"
        aria-controls="collapseExample"
        @click="showFilters = !showFilters"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-up"
        />
        <span>
          {{ $t('hide') }}
        </span>
      </button>

      <div
        id="collapseFilters"
        class="collapse"
      >
        <table class="defaultTable w-100">
          <colgroup>
            <col>
            <col>
            <col width="115">
          </colgroup>
          <thead>
            <tr>
              <th style="width: 150px;">
                {{ $t('key') }}
              </th>
              <th class="only-screen-xl">
                {{ $t('value') }}
              </th>
              <th style="width: 100px;">
                {{ $t('remove') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(filter, index) in filters"
              :key="`localStorageInformation-filterTableRow-${ index }`"
            >
              <td style="width: 250px;">
                {{ filter.key }}
              </td>
              <td class="only-screen-xl lineBreaks">
                {{ filter.value }}
              </td>
              <td style="width: 100px;">
                <button
                  class="btn btn-secondary btn-block btn-sm"
                  @click="openConfirmClearFilter(filter.key)"
                >
                  <font-awesome-icon
                    class="mr-2 gray"
                    icon="trash"
                  />
                  <span>{{ $t('remove') }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <SweetModal
        ref="confirmClearFilters"
        icon="warning"
        blocking
        title="Clear localStorage?"
        class="overflowHidden"
      >
        <p>{{ $t('localStorageInformationComponent.sureToClearLocalStorage') }}</p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmClearFilters.close()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="clearFilters()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />
          <span>{{ $t('remove') }}</span>
        </button>
        <div class="clearfix" />
      </SweetModal>

      <SweetModal
        ref="confirmClearFilter"
        icon="warning"
        blocking
        title="Clear localStorage item?"
        class="overflowHidden"
      >
        <p>{{ $t('localStorageInformationComponent.sureToClearLocalStorageItem') }}</p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmClearFilter.close()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="clearFilter()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />
          <span>{{ $t('remove') }}</span>
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>

    <template v-else>
      <p>{{ $t('noDataAvailable') }}</p>

      <SweetModal
        ref="confirmClearAll"
        icon="warning"
        blocking
        title="Clear entire localStorage?"
        class="overflowHidden"
      >
        <p>{{ $t('localStorageInformationComponent.sureToClearEntireLocalStorage') }}</p>
        <p class="red">
          {{ $t('localStorageInformationComponent.mightRemoveValuableData') }}
        </p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmClearAll.close()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="clearAll()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />
          <span>{{ $t('localStorageInformationComponent.clearEntireLocalStorage') }}</span>
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </Portlet>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'LocalStorageInformation',
  components: {
    SweetModal
  },
  data () {
    return {
      showFilters: false,
      filters: null,
      filterIndex: null
    }
  },
  computed: {
    filtersAvailable () {
      if (this.filters == null) {
        return false;
      }
      if (this.filters.length == null) {
        return false;
      }
      if (this.filters.length == 0) {
        return false;
      }
      return true;
    }
  },
  created () {
    this.getFilters();
  },
  methods: {
    getFilters () {
      this.filters = [];
      var keys = Object.keys(localStorage),
        i = keys.length;
      while ( i-- ) {
        this.filters.push( { value: localStorage.getItem(keys[i]), key: keys[i] } );
      }
    },
    clearFilters () {
      this.filters.forEach(filter => {
        localStorage.removeItem(filter.key);
      });
      this.$refs.confirmClearFilters.close();
      this.$router.push('/login');
    },
    openConfirmClearFilters () {
      this.$refs.confirmClearFilters.open();
    },

    clearFilter () {
      localStorage.removeItem(this.filterIndex);
      this.$refs.confirmClearFilter.close();
      this.getFilters();
    },
    openConfirmClearFilter (value) {
      if (value != null) {
        this.filterIndex = value;
      }
      this.$refs.confirmClearFilter.open();
    },

    clearAll () {
      localStorage.clear();
      location.reload();
    },
    openConfirmClearAll () {
      this.$refs.confirmClearAll.open();
    }
  }
}
</script>

